import { gql } from 'graphql-request'

export const getClusterQuarterProjectGoals = gql`
  query getClusterQuarterProjectGoals($clusterId: ID!, $date: DateRange!) {
    clusterQuarterProjectGoals(pharmacy_cluster_id: $clusterId, date: $date) {
      id
      pharmacy_cluster_id
      quarter {
        id
        from
        to
        label
      }
      project_id
      goal
      adjusted_goal(date: $date) {
        goal
        workdays
      }
    }
  }
`

export const getClusterProjectGoals = gql`
 query getClusterProjectGoals(
	 $clusterId: ID!, 
	 $date: DateRange!) {
    clusterProjectGoals(
    pharmacy_cluster_id: $clusterId, 
    date: $date) {
      id
      pharmacy_cluster_id
      from
      to
      project_id
      goal
      adjusted_goal(date: $date) {
        goal
        workdays
      }
    }
  }
`

export const getAllQuarterProjectGoals = gql`
  query allClusterQuarterProjectGoals($date: DateRange!) {
    allClusterQuarterProjectGoals(date: $date) {
      id
      quarter {
        id
        from
        to
      }
      project_id
      goal
      adjusted_goal(date: $date) {
        goal
        workdays
      }
    }
  }
`

export const allClusterProjectGoals = gql`
  query allClusterProjectGoals($date: DateRange! ) {
      allClusterProjectGoals(date: $date) {
        id
        from
        to
        project_id
        goal
        adjusted_goal(date: $date) {
          goal
          workdays
        }
      }
    }
  `
