import { useMutation, useQuery } from 'react-query'
import {
  ClusterQuarterProjectGoal,
  useAuth,
  useGqlClient,
  getClusterQuarterProjectGoals,
  getClusterProjectGoals,
  getAllQuarterProjectGoals,
  createProjectGoal,
  allClusterProjectGoals,
  createClusterProjectGoal
} from '@aposphaere/core-kit'
import { QueryOptions } from './utils'
import { toast } from '@aposphaere/ui-components'
import { format } from 'date-fns'
import { useCrmContext } from '../../contexts/crmContext'

type Props = {
  date: { from: string; to: string }
  clusterId: string
  enabled: boolean
  options?: QueryOptions<ClusterQuarterProjectGoal[]>
}

type AllGoalsProps = {
  date: { from: string; to: string }
  enabled: boolean
  options?: QueryOptions<ClusterQuarterProjectGoal[]>
}

export type GoalsMutationVariables = {
  pharmacyClusterId: number
  quarterId: number
  projectId: number
  goal: number
}

export type ProjectGoalsMutationVariables = {
  pharmacyClusterId: number
  from: Date,
  to: Date,
  projectId: number
  goal: number
}

export const useGoalsQuery = (props: Props) => {
  const gqlClient = useGqlClient()
  const auth = useAuth()
  const { activeCluster } = useCrmContext()
  const clusterId = props.clusterId || activeCluster.id
  const date = props.date
  return useQuery(
    ['goals', `cluster-${clusterId}`, `date-${JSON.stringify(props.date)}`],
    async () => {
      const data = await gqlClient.request(getClusterQuarterProjectGoals, {
        clusterId,
        date,
      })

      return data?.clusterQuarterProjectGoals
    },
    { enabled: !!auth.user && props.enabled, ...props.options },
  )
}

export const useProjectGoalsQuery = (props: Props) => {
  const gqlClient = useGqlClient()
  const auth = useAuth()
  const { activeCluster } = useCrmContext()
  const clusterId = props.clusterId || activeCluster.id
  const date = props.date
  return useQuery(
    ['projectGoals', `cluster-${clusterId}`, `date-${JSON.stringify(props.date)}`],
    async () => {
      const data = await gqlClient.request(getClusterProjectGoals, {
        clusterId,
        date,
      })
      return data?.clusterProjectGoals
    },
    { enabled: !!auth.user && props.enabled, ...props.options },
  )
}

export const useAllGoalsQuery = ({ date, options, enabled }: AllGoalsProps) => {
  const gqlClient = useGqlClient()
  const auth = useAuth()
  return useQuery(
    ['allGoals', `date-${JSON.stringify(date)}`],
    async () => {
      const data = await gqlClient.request(getAllQuarterProjectGoals, {
        date,
      })

      return data?.allClusterQuarterProjectGoals
    },
    { enabled: !!auth.user && enabled, ...options },
  )
}

export const useAllProjectGoalsQuery = ({ date, options, enabled }: AllGoalsProps) => {
  const gqlClient = useGqlClient()
  const auth = useAuth()
  return useQuery(
    ['allProjectGoals', `date-${JSON.stringify(date)}`],
    async () => {
      const data = await gqlClient.request(allClusterProjectGoals, {
        date,
      })
      return data?.allClusterProjectGoals
    },
    { enabled: !!auth.user && enabled, ...options },
  )
}

export const useGoalsMutation = () => {
  const gqlClient = useGqlClient()

  const addGoal = async (variables: GoalsMutationVariables) => await gqlClient.request(createProjectGoal, variables)

  return useMutation(addGoal, {
    onSuccess: () => {
      toast.show({ headline: 'Ziel wurde hinzugefügt.', type: 'success' })
    },
    onError: () => {
      toast.show({ headline: 'Etwas ist schief gelaufen.', type: 'error' })
    },
  })
}

export const useProjectGoalsMutation = () => {
  const gqlClient = useGqlClient();
  const addGoal = async (variables: ProjectGoalsMutationVariables) => {
    const formattedVariables = {
      ...variables,
      from: format(new Date(variables.from), 'yyyy-MM-dd'),
      to: format(new Date(variables.to), 'yyyy-MM-dd'),
    };
    return await gqlClient.request(createClusterProjectGoal, formattedVariables)
  }
  return useMutation(addGoal, {
    onSuccess: () => {
      toast.show({ headline: 'Ziel wurde hinzugefügt.', type: 'success' })
    },
    onError: () => {
      toast.show({ headline: 'Etwas ist schief gelaufen.', type: 'error' })
    },
  })
}
